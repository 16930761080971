import LOCALES from "./locales"

const messages = {
  [LOCALES.ENGLISH]: {
    helmet_title: "Web-development | anclaev",
    helmet_description: "Developing progressive web applications",
    helmet_keywords: "web development, websites, web design",
    helmet_og: "Web design",

    home_title: "Developing progressive web applications",
    home_subtitle: "Frontend & web design",
  },
  [LOCALES.RUSSIAN]: {
    helmet_title: "Веб-разработка | anclaev",
    helmet_description: "Разработка прогрессивных веб-приложений",
    helmet_keywords: "веб-разработка, сайты, веб-дизайн",
    helmet_og: "Веб-дизайн",

    home_title: "Разработка прогрессивных веб-приложений",
    home_subtitle: "Фронтенд & веб-дизайн",
  },
}

export default messages
