import { StrictMode } from "react"
import { render } from "react-dom"
import { Provider } from "react-redux"

import store from "./store"

import App from "./App"

render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>,
  document.body.getElementsByClassName("app")[0]
)
